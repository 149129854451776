module services {
    export module consignment {
        export class consignmentDetailService implements interfaces.consignment.IConsignmentDetailService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getPackingList(conId: number): ng.resource.IResourceClass<interfaces.consignment.IConsignmentDetailPacking> {
                return this.$resource<interfaces.consignment.IConsignmentDetailPacking>(this.ENV.DSP_URL + "ConsignmentDetail/GetPackingList", {
                    conId: conId
                }, {
                        query: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            isArray: true
                        }
                    });
            }

            generatePackaging(consignmentId: number, containersizeId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentDetail/GeneratePackaging", {
                    consignmentId: consignmentId,
                    containersizeId: containersizeId
                });
            }

            updateProRataTotals(consignmentId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentDetail/UpdateProRataTotals", {
                    conId: consignmentId
                });
            }

            delete(consignmentDetailId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentDetail/Delete", {
                    codId: consignmentDetailId
                });
            }

            deletePacking(commercialInvoiceSKUId: number, commercialInvoiceItemTrackingId?: number, containerConsignmentInvoiceDetailId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentDetail/DeletePacking", {
                    commercialInvoiceSKUId: commercialInvoiceSKUId,
                    commercialInvoiceItemTrackingId: commercialInvoiceItemTrackingId,
                    containerConsignmentInvoiceDetailId: containerConsignmentInvoiceDetailId
                });
            }

            updatePackingList(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentDetail/UpdatePackingList", {
                });
            }

            get(codId: number, conId?: number): ng.resource.IResourceClass<interfaces.consignment.IConsignmentDetail> {
                return this.$resource<interfaces.consignment.IConsignmentDetail>(this.ENV.DSP_URL + "ConsignmentDetail/Get", {
                    codId: codId,
                    conId: conId
                }, {
                        get: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            isArray: false
                        }
                    });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentDetail/Save");
            }


        }
    }
    angular.module("app").service("consignmentDetailService", services.consignment.consignmentDetailService);
}